<template>
  <div>
    <!--    <div class="title">{{ $t("adnavPage.UserManagement") }}</div>-->
    <el-col :span="24" style="margin-bottom: 10px;">
      <h3>{{ $t('unitI18n.totalAccount') }}{{ Number(tableTotalUnit) | toThousandFilterTwo }} {{ $t('millionDollars') }}</h3>
    </el-col>
    <el-col :span="24" style="margin-bottom: 10px;">
      <el-select v-model="optionsFeeVal" @change="changeFree">
        <el-option
          v-for="item in optionsFee"
          :key="item.valueNew"
          :label="$t(item.label)"
          :value="item.valueNew"
        ></el-option>
      </el-select>
    </el-col>
    <el-table :data="tableData">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column prop="type" :label="$t('tableI18n.Type')" align="center">
        <template v-slot="scope">
          <div>{{scope.row.type | filterUnit}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="paymentUser.username"
        :label="$t('tableI18n.PayAccount')"
        align="center"
      ></el-table-column>
      <el-table-column prop="token" :label="$t('tableI18n.Unit')" align="center">
        <template v-slot="scope">
          <div>{{Number(scope.row.token) | toThousandFilterTwo}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" :label="$t('tableI18n.DateTime')" align="center"></el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import AdPagination from "@/components/ad-pagination";

export default {
  name: "user-list",
  components: {
    AdPagination
  },
  data() {
    const validRePwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('loginPage.passwordErr')));
      } else if (value !== this.formUser.password) {
        callback(new Error(this.$t('loginPage.passwordReErr')));
      } else {
        callback();
      }
    };
    return {
      tableLoading: false,
      tableData: [],
      tableTotal: 0,
      tableTotalUnit: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      optionsFee: [],
      optionsFeeVal: "",
      isSubmit: false,
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.optionsFee = this.$enums.FEE_TYPE;
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.initData();
    },
    initData() {
      this.tableLoading = true;
      let params = { page: this.tablePageCP, pageSize: this.tablePagePS, type: this.optionsFeeVal };
      if (!this.optionsFeeVal) delete params.type;
      this.$axios.get('/manage-admin/token-log', { params: params }).then((response) => {
        this.tableLoading = false;
        if (!response.code) {
          this.tableData = response.data.rows || [];
          this.tableTotal = response.data.count || 0;
          this.tableTotalUnit = response.data.unitToken || 0;
        }
      }).catch(error => {
        console.log(error);
        this.tableLoading = false;
      });
    },
    changeFree() {
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.list-opt {
  margin: 0 5px;
}
</style>
