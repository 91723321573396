<template>
  <el-col :span="24" class="flex-base table-page">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage_"
        :page-size="pageSize_"
        :page-sizes="pageSizes"
        layout="slot"
        :total="pageTotal">
      <span class="el-pagination__jump">{{ $t("page.goto") }}<el-input v-model="currentPage_Temp" type="number" class="el-input el-pagination__editor is-in-pagination" @change="handleCurrentChange"></el-input>{{ $t("page.pageClassifier") }}</span>
    </el-pagination>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage_"
        :page-size="pageSize_"
        :page-sizes="pageSizes"
        layout="prev, pager, next"
        :total="pageTotal">
    </el-pagination>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage_"
        :page-size="pageSize_"
        :page-sizes="pageSizes"
        layout="slot"
        :total="pageTotal">
      <span class="el-pagination__sizes">
        <el-select v-model="pageSize_" size="mini" @change="handleSizeChange(pageSize_)">
          <el-option
              v-for="item in pageSizes"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        {{ $t("page.pagesize") }}
      </span>
    </el-pagination>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage_"
        :page-size="pageSize_"
        :page-sizes="pageSizes"
        layout="slot"
        :total="pageTotal">
      <span class="el-pagination__total">{{ $t("page.total", {total: pageTotal}) }}</span>
    </el-pagination>
  </el-col>
</template>

<script>
export default {
  name: "ad-pagination",
  props: {
    position: {
      type: String,
      required: false,
      default: "right"
    },
    layout: {
      type: String,
      required: false,
      default: "total, sizes, prev, pager, next, jumper"
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1
    },
    pageSize: {
      type: Number,
      required: true,
      default: 10
    },
    pageSizes: {
      type: Array,
      required: false,
      default: () => { return [10, 20, 30, 40, 50]; }
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
      return {
        currentPage_Temp: 1,
        currentPage_: 1,
        pageSize_: 10,
        pageObj: {
          pageSize: 10,
          currentPage: 1,
        }
      };
  },
  created() {
    this.pageObj.pageSize = this.pageSize;
    this.pageObj.currentPage = this.currentPage;
    this.currentPage_Temp = this.currentPage;
    this.currentPage_ = this.currentPage;
    this.pageSize_ = this.pageSize;
  },
  watch: {
    $router: {
      handler: function(){
        this.pageObj.pageSize = 10;
        this.pageObj.currentPage = 1;
        this.currentPage_Temp = 1;
        this.currentPage_ = 1;
        this.pageSize_ = 10;
      },
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize_ = val;
      this.pageObj.pageSize = val;
      let ps = Math.ceil(this.pageTotal / this.pageSize_);
      if (this.currentPage_ > ps) {
        this.currentPage_ = ps;
        this.currentPage_Temp = ps;
        this.pageObj.currentPage = this.currentPage_;
      }
      this.$emit("handlePage", this.pageObj);
    },
    handleCurrentChange(val) {
      this.currentPage_ = Number(val) || 1;
      this.currentPage_ = this.currentPage_ || 1;
      this.currentPage_Temp = this.currentPage_ || 1;
      let ps = Math.ceil(this.pageTotal / this.pageSize_);
      if (this.currentPage_ > ps) {
        this.currentPage_ = ps;
        this.currentPage_Temp = ps;
        return false;
      }
      this.pageObj.currentPage = this.currentPage_;
      this.$emit("handlePage", this.pageObj);
    }
  }
};
</script>

<style lang="scss">
.is-in-pagination {
  .el-input__inner {
    padding: 0 3px;
  }
}
</style>
<style scoped lang="scss">
.table-page {
  flex-direction: row-reverse;
  align-items: center;
  height: 60px;
}
</style>
